<!--
  发布需求、发布需求
    + 服务机构
      + 可以发布单个需求和组合需求
  发布完单个需求后，切换到发布成功
  发布完组合需求后，不需要切换
 -->
<template>
  <div class="detail">
    <detail-header title="发布需求" />
    <div class="detail_main page_width">
      <div class="tabs_wrapper">
        <el-tabs v-model="curActiveTab">
          <el-tab-pane
            label="单个需求"
            name="single"
            laze>
            <create-single
              @addSuccess="addSuccess">
            </create-single>
          </el-tab-pane>
          <el-tab-pane
            label="组合需求"
            name="chain"
            laze>
            <create-chain></create-chain>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import createSingle from './components/create-single'
import createChain from './components/create-chain'
export default {
  data () {
    return {
      // 当前激活的
      curActiveTab: 'single'
    }
  },
  components: {
    createSingle,
    createChain
  },
  methods: {
    // 需求添加成功
    addSuccess (data) {
      if (this.curActiveTab === 'single') {
        this.$router.push({
          path: '/require-create-success',
          query: {
            serviceTypeId: data.serviceTypeId,
            title: data.title,
            content: data.description,
            keywords: data.keywords || data.title
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .detail {
    .detail_main {
      padding: 30px 0;
      .tabs_wrapper {
        width: 820px;
        margin: 0 auto;
      }
    }
  }
</style>
