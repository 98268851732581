<!--
  创建单个需求
 -->
<template>
  <div class="form_wrapper">
    <create-form
      ref="createForm">
    </create-form>
    <div class="btn_box">
      <el-button
        type="primary"
        size="small"
        :loading="btnLoading"
        @click="createRequire">
        发布需求
      </el-button>
    </div>
  </div>
</template>

<script>
import createForm from '../../../components/require-create/create-form'
import * as api from '@/api'
export default {
  data () {
    return {
      btnLoading: false
    }
  },
  components: {
    createForm
  },
  methods: {
    // 发布需求
    async createRequire () {
      console.log(this.$refs.createForm)
      let data = {}
      try {
        data = await this.$refs.createForm.validate()
        this.createRequirement(data)
      } catch (err) {
        console.log(err)
        return
      }
      console.log('进行需求的发布')
    },

    // 发布新需求
    createRequirement (data) {
      this.btnLoading = true
      api.createRequirement(data).then(res => {
        if (res.data.code === 0) {
          this.$message.success('发布需求成功！')
          this.$emit('addSuccess', data)
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.btnLoading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .form_wrapper {
    padding: 15px 0;
    .btn_box {
      padding: 30px 0 0 180px;
    }
  }
</style>
