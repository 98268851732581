<template>
  <div class="chain_wrapper">
    <div
      class="require_item"
      v-for="(item, index) in requireList"
      :key="item.id">
      <!-- 编辑模式 -->
      <template v-if="curAction.id === item.id">
        <div class="form_info">
          <create-form :requireForm.sync="curAction" :ref="item.id"></create-form>
        </div>
        <div class="btn_box">
          <div><el-button type="text" @click="saveEdit(item.id)">保存编辑</el-button></div>
          <div><el-button type="text"><span class="tw-text-danger" @click="cancelEdit">取消编辑</span></el-button></div>
        </div>
      </template>
      <!-- 展示模式 -->
      <template v-else>
        <div class="form_info">
          <h1>{{ index + 1 }}.{{ item.title }}</h1>
          <p>{{ item.description }}</p>
        </div>
        <div class="btn_box">
          <div><el-button type="text" @click="editRequireItem(item)">编辑需求</el-button></div>
          <div><el-button type="text"><span class="tw-text-danger" @click="deleteRequireItem(item)">删除需求</span></el-button></div>
        </div>
      </template>
    </div>
    <!-- 添加模式 -->
    <div>
      <div class="require_item" v-if="curAction === 'add'">
        <div class="form_info tw-pt-20px">
          <create-form ref="addForm"></create-form>
        </div>
        <div class="btn_box">
          <template>
            <div><el-button type="text" @click="addRequire">暂存需求</el-button></div>
            <div><el-button type="text"><span class="tw-text-danger" @click="curAction = {}">取消新增</span></el-button></div>
          </template>
        </div>
      </div>
      <div class="add_require_box" v-else @click="curAction = 'add'">
        <i class="el-icon-plus"></i>
        <span>新增需求</span>
      </div>
    </div>
    <div class="tw-mt-40px">
      <el-button type="primary" size="small" :loading="btnLoading" @click="createRequirementChain">发布需求</el-button>
    </div>
  </div>
</template>

<script>
import createForm from '../../../components/require-create/create-form'
import { getUid } from '@/utils/utils'
import * as api from '@/api'
export default {
  data () {
    return {
      btnLoading: false,
      // 需求列表
      requireList: [],
      // 当前操作
      curAction: {}
    }
  },
  components: {
    createForm
  },
  methods: {
    // 保存编辑
    async saveEdit (id) {
      let data = {}
      try {
        data = await this.$refs[id][0].validate()
        const index = this.requireList.findIndex(rItem => {
          return rItem.id === data.id
        })
        if (index !== -1) {
          this.requireList[index] = data
        }
        this.curAction = {}
      } catch (err) {
        console.log(err)
        return
      }
      console.log('保存编辑')
    },
    // 取消编辑
    cancelEdit () {
      this.curAction = {}
    },

    // 在添加需求的表单中，向需求列表添加一条
    async addRequire () {
      let data = {}
      try {
        data = await this.$refs.addForm.validate()
        data.id = getUid()
        this.requireList.push(data)
        this.curAction = {}
      } catch (err) {
        console.log(err)
        return
      }
      console.log('进行需求的发布')
    },

    // 点击编辑需求按钮
    editRequireItem (item) {
      // this.curAction = item.id
      this.curAction = this.$clone(item)
    },
    // 点击删除需求按钮
    deleteRequireItem (item) {
      const index = this.requireList.findIndex(rItem => {
        return rItem.id === item.id
      })
      if (index !== -1) {
        this.requireList.splice(index, 1)
      }
    },

    // 发布组合需求
    createRequirementChain () {
      this.btnLoading = true
      api.createRequirementChain(this.requireList).then(res => {
        if (res.data.code === 0) {
          this.$message.success('发布需求成功！')
          this.reset()
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.btnLoading = false
      })
    },

    // 重置
    reset () {
      this.requireList = []
      this.curAction = {}
    }
  }
}
</script>

<style lang="scss" scoped>
  .chain_wrapper {
    padding: 5px 0;
    .require_item {
      display: flex;
      justify-content: space-between;
      width: 820px;
      background: #FFFFFF;
      border: 1px solid #DCDFE6;
      border-radius: 6px;
      padding: 10px 40px 20px 30px;
      margin-bottom: 20px;
      .form_info {
        width: 560px;
        & > h1 {
          font-size: 16px;
          font-weight: bold;
          line-height: 60px;
          color: #303133;
          margin-bottom: 10px;
        }
        & > p {
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          color: #303133;
        }
      }
      .btn_box {
        display: flex;
        flex-direction: column;
      }
    }
    .add_require_box {
      width: 100%;
      height: 32px;
      background: #FFFFFF;
      border: 1px solid #DCDFE6;
      opacity: 1;
      border-radius: 4px;
      color: #3473E6;
      line-height: 32px;
      font-size: 14px;
      padding: 0 30px;
      cursor: pointer;
      & > span {
        margin-left: 8px;
      }
    }
  }
</style>
